import React, { useState, useEffect } from 'react';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

recognition.continuous = true;
recognition.interimResults = true;
recognition.lang = 'en-US';

const App = () => {
    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);

    const toggleListening = () => {
        if (isListening) {
            recognition.stop();
            setIsListening(false);
        } else {
            setTranscript('');
            recognition.start();
            setIsListening(true);
        }
    };

    const handleSpeech = (event) => {
        const currentTranscript = Array.from(event.results)
            .map(result => result[0].transcript)
            .join('');

        setTranscript(currentTranscript);
    };

    useEffect(() => {
        recognition.addEventListener('result', handleSpeech);
        recognition.addEventListener('end', recognition.start);

        return () => {
            recognition.removeEventListener('result', handleSpeech);
            recognition.removeEventListener('end', recognition.start);
        };
    }, []);

    return (
        <div>
            <button onClick={toggleListening}>
                {isListening ? 'Stop Listening' : 'Start Listening'}
            </button>
            <p style={{color:"white"}}>{transcript}</p>
        </div>
    );
};

export default App;
