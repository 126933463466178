import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Landing from './Landing';
import Tos from './Tos';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Article1 from './Article1';
import Feed from './Feed';
import Chat from './Chat';
import Workspace from './Workspace';
import Call from './Call';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://68966c9dbaa5d2ba231dae27ecb5e467@o4506620375334912.ingest.sentry.io/4506620377825280",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    [
      <Route path="/" element={<App />} />,
      <Route path="/generator" element={<Landing />} />,
      <Route path="/generate" element={<App />} />,
      <Route path="tos" element={<Tos />} />,
      <Route path="feed" element={<Feed />} />,
      <Route path="chat" element={<Chat />} />,
      <Route path="workspace" element={<Workspace />} />,
      <Route path="call" element={<Call />} />,
      <Route path="success" element={<Chat />} />


    ]
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);

function ErrorPage() {
  // Error handling logic
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Something went wrong.</p>
    </div>
  );
}
