/* global gtag stripe */

import React, { createContext, useContext, useState, useEffect, useRef} from 'react';
import './Chat.css';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { signOut } from "firebase/auth";
import { set } from 'react-ga';
import Swal from 'sweetalert2'
import Drawer from '@mui/joy/Drawer';
import Box from '@mui/joy/Box';
import {messaging} from './firebase';
import { getToken } from "firebase/messaging";
import { Link } from "react-router-dom";
import sponsordata from './sponsordata.json';
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Chat() {
  const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com" //https://lollipopchat-ca692e070ff0.herokuapp.com
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [user, setUser] = useState(null);

  const sweety = (message) => {
      Swal.fire({
        text: message,
        background: "rgba(255, 0, 189, 0.9)",
        position: 'top',
        showConfirmButton: false,
        color:"white",
        backdrop:false,
        timer: 5000,
        timerProgressBar: true,
        fontSize:"12px",
        width:"100vw"
      })
    }

  const [solanaModalOpen, setSolanaModalOpen] = useState(false);

  useEffect(() => {
      auth.onAuthStateChanged(user => {
        setUser(user);
      });
    }, [user]);

    const [models, setModels] = useState([]);

  useEffect(() => {
    fetch(`${endpoint}/models`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      //get rid of instagram field for all models
      data.forEach((model) => {
        delete model.instagram
      })
      
      setModels(data)
    })
  }, [])


  const [model, setModel] = useState();

  useEffect(() => {
    let girl = new URLSearchParams(window.location.search).get('lollipopgirl') || "chat"
    setModel(girl)
    selectModel(girl)
  }, [models])

  const [modelData, setModelData] = useState({});

  const selectModel = (model_key) => {
    /**
    if(messageLoading){
      sweety("Wait for the reply first!")
      return
    }
    */
    setModel(model_key)
    const modelObject = models?.find(model => model.key == model_key)
    setModelData(modelObject)
    setOpen(false)
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  }

  const getModel = async () => {
    models?.find(model => model.key === model)
  }

    
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
      setIsTosModalOpen(false)
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const [subscribed, setSubscribed] = useState(false);



  const getNotified = () => {
    // Helper function to handle token retrieval and API call
    const getTokenAndCallAPI = () => {
      getToken(messaging, { "vapidKey": "BGHbf8v41DDLpzDY16h3tP2KfAt4rkZbmk832ZPABVuryulJqtzNFQyNegMMFjBA7hurNmfOVuELNo3oCiLfiZw" }).then((currentToken) => {
        if (currentToken) {
          console.log(currentToken);
          console.log("Got token");
  
          fetch(`${endpoint}/notificationsignup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user?.email, notification: currentToken })
          })
          .then(response => {
            if (response.ok) {
              // Set flag in local storage
              localStorage.setItem('registeredForNotifications', 'true');
              setSubscribed(true);
              console.log("Notification registration successful");
            } else {
              // Handle non-OK responses
              console.log("Error registering notification with the server.");
            }
            return response.json();
          })
          .then(res => {
            console.log(res);
          });
        } else {
          console.log('No registration token available.');
        }
      }).catch((err) => {
        sweety("Are your push notifications enabled? Look for bell icon above.")
        console.log('An error occurred while retrieving token. ', err);
      });
    };
  
    // Check if the user is already registered for notifications
    const isRegisteredForNotifications = localStorage.getItem('registeredForNotifications') === 'true';
  
    if (!isRegisteredForNotifications) {
      navigator.serviceWorker.ready.then((registration) => {

      console.log('Requesting permission...');
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          getTokenAndCallAPI();
        } else {
          sweety("Notifications permission denied.");
          console.log('Permission denied by the user.');
        }
      }).catch((err) => {
        sweety("Error requesting notifications permission.");
        console.log('Permission request error: ', err);
      });
    })
    } else {
      console.log("User is already registered for notifications.");
    }
  };

  useEffect(() => {
    if(user){
      const getQueryParam = (name) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(name);
      };
      const referrer = getQueryParam('referrer');

      if(referrer){
        console.log("URL has referrer.")
      }
      else{
        console.log("No referrer in query parameters.")
        return
      }
      if(!localStorage.getItem('referrer')){
        console.log("No referrer in localstorage. Going to set one.")
      }
      else{
        console.log("Already set referrer.")
        return
      }
      const authToken = auth.currentUser.getIdToken();
      if (referrer) {
        fetch(`${endpoint}/referral`, {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + authToken,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ referrer: referrer })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Assuming the server responds with JSON
        })
        .then(res => {
          console.log(res)
          localStorage.setItem('referrer', getQueryParam('referrer'));

        })
        .catch(error => console.error('Error setting referrer:', error));
      }
    }
  }, [user])
  
  


  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setMessages({});
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


    const bottomRef = useRef(null);

    const [theme, setTheme] = useState('light'); // Default theme is light
    const [messageLoading, setMessageLoading] = useState(false);
    const [messageLoadingBubbles, setMessageLoadingBubbles] = useState({});


    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    const logmein = () =>{
      setIsTosModalOpen(true)
      setOpen(false)
    }


    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const [isNearBottom, setIsNearBottom] = useState(false);

    // Function to check if the bottomRef is in view or within 300px
    const checkBottomInView = () => {
      if (bottomRef.current) {
        const rect = bottomRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        const isNear = rect.top < window.innerHeight + 300;
        setIsNearBottom(isVisible || isNear);
      }
    };


  
    useEffect(() => {
      // Check on initial mount
      checkBottomInView();
  
      // Add scroll event listener
      window.addEventListener('scroll', checkBottomInView);
  
      // Cleanup on unmount
      return () => {
        window.removeEventListener('scroll', checkBottomInView);
      };
    }, []);

    // Fetch messages when the component mounts
    useEffect(() => {
      console.log(user)
      console.log(modelData?.name)
      async function fetchMessages() {
      
      const authToken = await auth?.currentUser?.getIdToken();

      if (authToken) {
        console.log("FETCHING!!!")
              fetch(`${endpoint}/get-messages`, {
                  method: 'POST',
                  headers: {
                      'Authorization': 'Bearer ' + authToken,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ model_key: model })
              })
                  .then(response => response.json())
                  .then(data => {
                      console.log(data);
  
                      const newMessages = [
                          { role: 'system', content: modelData?.greeting_photo },
                          { role: 'ai', content: `Hey! I'm ${modelData?.name}. Apparently I'm your AI slut now. Kinda excited! 🥰` },
                          { role: 'ai', content: "By the way... I can chat about ANYTHING. I can send you **voice messages**. Aaand... I can **take photos**. Just ask." }
                      ];
  
                      if (data?.length === 0 || data?.error) {
                          setMessages(prevMessages => ({
                              ...prevMessages,
                              [modelData?.name]: newMessages
                          }));
                      } else {
                          setMessages(prevMessages => ({
                              ...prevMessages,
                              [modelData?.name]: newMessages.concat(data)
                          }));
                      }
                  })
                  .catch(error => {
                      console.error('Error fetching messages:', error);
                  });

      } else {
          setMessages(prevMessages => ({
              ...prevMessages,
              [modelData?.name]: [
                  { role: 'system', content: modelData?.greeting_photo },
                  { role: 'ai', content: `Hey! I'm ${modelData?.name}. Apparently I'm your girlfriend now. Kinda excited! 🥰` },
                  { role: 'ai', content: "By the way... I can chat about ANYTHING. I can send you **voice messages**. Aaand... I can **take photos**. Just ask." }
              ]
          }));
      }
    }
    fetchMessages();
  }, [user, modelData?.name]);
  

    
    const generateImage = async () => {
        const authToken = await auth.currentUser.getIdToken();
        let messagetosend = newMessage;
        setNewMessage('');
        setMessageLoading(true)
        fetch(`${endpoint}/generate-image-chat?prompt=${encodeURIComponent(messagetosend)}`,{
            headers: {
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                // Assuming the response contains the image URL
                const imageUrl = data.image_url;
                
                // Update chat messages with the new image URL
                const newImageMessage = { role: 'system', content: imageUrl };
                setMessages([...messages, newImageMessage]);

                // Clear the input field
                setNewMessage('');
                setMessageLoading(false)
            })
            .catch(error => console.error('Error generating image:', error));
    };

    const improveImage = async (url) => {
      if(credits < 2){
        sweety("Your free trial has run out.")
        return
      }
      sweety("Upscaling the image. This may take up to a minute.")
      setMessageLoading(true)
      setMessages(prevMessages => ({
        ...prevMessages,
        [modelData?.name]: [
            ...(prevMessages[modelData?.name] || []),
            { role: 'ai', content: "I'll make the image better and send it to you in a minute or so." }
        ]
        }));
          setTimeout(() => {
        scrollToBottom()
      }, 500);

      const authToken = await auth.currentUser.getIdToken();

      fetch('https://us-central1-chaind.cloudfunctions.net/api/upscale-image', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageUrl: url })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Assuming the server responds with JSON
    })
    .then(res => {
        console.log(res);
        setMessages(prevMessages => ({
          ...prevMessages,
          [modelData?.name]: [
              ...(prevMessages[modelData?.name] || []),
              res
          ]
        }));
        setMessageLoading(false);
        sweety("Image upscaled. Enjoy!");
        setCredits(credits-2);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
    })
    .catch(error => {
        console.error('Error generating image:', error);
        sweety("Something went wrong. Please try again.");
        setMessageLoading(false);
    });
    
    }

    
    const generateVoice = async () => {
        const authToken = await auth.currentUser.getIdToken();
        let messagetosend = newMessage;
        setNewMessage('');
        setMessageLoading(true)
        fetch(`${endpoint}/generate-voice`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: messagetosend })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Assuming the server responds with JSON
        })
        .then(res => {
            const audioUrl = res.audio_url;
            const newVoiceMessage = { role: 'audio', content: audioUrl, type: 'audio' };
            setMessages(prevMessages => ({
              ...prevMessages,
              [modelData?.name]: [
                  ...(prevMessages[modelData?.name] || []),
                  newVoiceMessage
              ]
          }));
            setNewMessage('');
            setMessageLoading(false)
        })
        .catch(error => console.error('Error generating voice:', error));
    };    
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };
    


    const sendMessage = async () => {
        if(!user){
            sweety("Please log in to chat! You'll get free tokens.")
            setIsTosModalOpen(true)
            return
        }
        if(credits <= 0){
            sweety("Your trial has run out.")
            return
        }
        bottomRef.current.scrollIntoView({ behavior: "smooth" });

        if(messageLoading){
            sweety("You have an ongoing conversation already, wait for the messages.")
            return
        }
        const authToken = await auth.currentUser.getIdToken();

        setMessageLoading(true)
        setTimeout(() => {
                setMessageLoadingBubbles(prevState => ({
                  ...prevState,
                  [modelData?.name]: true
                }));
                setTimeout(() => {
                bottomRef.current.scrollIntoView({ behavior: "smooth" });

            }, 100);

        }, 1000);
        const userMessage = { role: 'user', content: newMessage };
        setMessages(prevMessages => ({
          ...prevMessages,
          [modelData?.name]: [
              ...(prevMessages[modelData?.name] || []),
              userMessage
          ]
        }));
        bottomRef.current.scrollIntoView({ behavior: "smooth" });

        let messagetosend = newMessage;
        setNewMessage('');
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
        try {
          const response = await fetch(`${endpoint}/chatapplolligirls`, {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + authToken,
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ message: messagetosend, model: model })
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const responseText = await response.text();
  
          try {
              const responseData = JSON.parse(responseText);
              setMessages(prevMessages => ({
                ...prevMessages,
                [modelData?.name]: [
                    ...(prevMessages[modelData?.name] || []),
                    responseData
                ]
              }));
              } catch (e) {
              throw new Error("Received non-JSON response from server.");
          }
  
          setMessageLoading(false);
          setMessageLoadingBubbles(prevState => ({
            ...prevState,
            [modelData?.name]: false
          }));
          setCredits(credits - 1);
  
      } catch (error) {
          console.error('Error sending message:', error);
          sweety("Something went wrong, please try again.");
          setMessageLoading(false);
          setMessageLoadingBubbles(prevState => ({
            ...prevState,
            [modelData?.name]: false
          }));
      }
    };

    const [credits, setCredits] = useState(0);

    useEffect(() => {
        if(user){
            fetchCredits(user?.email); 
        }
    }, [user]);

    const fetchCredits = async (userEmail) => {
        try {
          const response = await fetch(`${endpoint}/read-credits`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail }),
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
      
          if (data.status === 200 && data.error_code === 0) {
            // Successfully retrieved token balance
            console.log('Token Balance:', data.token_balance);
            setCredits(data.token_balance);
          } else {
            console.error('Error fetching credits:', data.message);
            return null; // or handle error appropriately
          }
        } catch (error) {
          console.error('There was a problem fetching user credits:', error);
        }
      };

      const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
      
      const openPaymentModal = () => {
        setOpen(false);
        setIsPaymentModalOpen(true);
      }

      const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

      const FeedbackModal = ({onClose}) => {
        const [feedBack, setFeedBack] = useState("");
  
        const sendFeedback = async () => {
          if(feedBack.length < 30){
            sweety("Please think it through and write proper feedback.")
            return
          }
          setIsFeedbackModalOpen(false)
          console.log(feedBack)
          console.log(feedBack.length)

          const authToken = await auth.currentUser.getIdToken();
          console.log(authToken)

          fetch(`${endpoint}/feedback`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user?.email, message: feedBack })
          })
          .then(response => {
            if(response.ok){
              sweety("Thanks for the feedback! You got 5 free tokens.")
              fetchCredits(user?.email)
            }
            else{
              sweety("Feedback submitted. No free tokens though, as you already gave feedback.")
            }
            return response.json();
          })
          .then(res => {
            console.log(res);
          });
        
      }
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal modal3" onClick={e => e.stopPropagation()}>
              <div className="feedbackTitle">Submit feedback, get 5 free tokens!</div>
              <textarea rows={10} className="feedbackInput" value={feedBack} placeholder="Write your detailed feedback here. Any problems you had, your wishlist for features you'd like to use. Anything is welcome!" onChange={(e)=>setFeedBack(e.target.value)}></textarea>
              <button className="feedbackButton" onClick={()=>sendFeedback()}>Submit feedback</button>
            </div>
          </div>
        );
      }

      const [isSponsorModalOpen, setIsSponsorModalOpen] = useState(false);


const SolanaModal = ({ onClose }) => {
  return(
  <div className="backdrop" onClick={onClose}>
    <div className="modal modal3" onClick={e => e.stopPropagation()}>
      <div className="solanaModal">
        <div className="solanaModalTitle" style={{fontSize:"24px", fontWeight:600}}>Solana very gut.</div>
        <div className="solanaModalText">We are working on integrating Solana payments. Stay tuned!</div>
        <button>Try app</button>
        <button>Invest</button>
      </div>
    </div>
  </div>
  )
}

const SponsorModal = ({ url, onClose }) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
      <div className="sponsorWrapper2">
      <a href="https://www.aipornmakers.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="aipornmakers.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">The only list you'll ever need.</div>
          <div className="sponsorCardName">AIPornMakers.com</div>
          <div className="sponsorCardDescription">The ultimate AI Porn maker list.</div>
        </div>
      </a>
      <a href="https://www.theporngoat.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porngoat.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Explore a world of unlimited pleasure</div>
          <div className="sponsorCardName">ThePornGoat.com</div>
          <div className="sponsorCardDescription">Safest porn sites of all time.</div>
        </div>
      </a>
      <a href="https://bestpremiumpornsite.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porncouch.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Best Premium Porn Site</div>
          <div className="sponsorCardName">BestPremiumPornSite.com</div>
          <div className="sponsorCardDescription">Best adult world directory.</div>
        </div>
      </a>
      <a href="https://www.theporncouch.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porncouch.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Your sofa is waiting.</div>
          <div className="sponsorCardName">ThePornCouch.com</div>
          <div className="sponsorCardDescription">The ultimate safe porn list.</div>
        </div>
      </a>
      <a href="https://www.aipornhub.net/" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="https://www.aipornhub.net/images/dark-logo.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Detailed generation with full control.</div>
          <div className="sponsorCardName">AIPornhub.net</div>
          <div className="sponsorCardDescription">Advanced NSFW art generation.</div>
        </div>
      </a>
      <a href="https://reddit.com/r/aipornhub" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="reddit.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Reddit's best AI Porn</div>
          <div className="sponsorCardName">r/AIPornHub</div>
          <div className="sponsorCardDescription">Biggest NSFW art subreddit.</div>
        </div>
      </a>
    </div>
      </div>
    </div>
  );
};   

const paymentWithStripe = async (price_id) => {
  const stripe = await loadStripe('pk_live_51OMTabEsKxxKHfJyzJZA51h82xgLRz3YxS1B8CWWRaMPDl9ta5x8PRQNCAebuSouKr1bYOKEU2LgE9t4nc1R49mY00qqxFba3v');

  fetch(`${endpoint}/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({tolt_referral: window.tolt_referral, email:user?.email, price_id: price_id}), // Make sure this matches your server-side logic
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Network response was not ok.');
    }
  })
  .then(session => {
    // Debugging: Log the session ID to make sure it's received correctly
    console.log("Session ID:", session.id);
    
    if(session.id) {
      stripe.redirectToCheckout({sessionId: session.id});
    } else {
      throw new Error('Session ID is undefined.');
    }
  })
  .catch(error => console.error('Error:', error));
};





      const PaymentModal = ({ url, onClose }) => {
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal modal3" onClick={e => e.stopPropagation()}>
              <div className="stripePayments"><img src="stripe.png" width="32px"></img> Secure payments with Stripe</div>
              <div className="payment-options">
                <div className="payment-card">
                  <h3>Starter Fantasy</h3>
                  <p>Love pussy, but want a sweet deal? <br/>This is for you!</p>
                  <div className="payButtonWrapper">
                    <a 
                      className="payButton"
                      onClick={() => {
                        paymentWithStripe("price_1OUnhhEsKxxKHfJy2BGMvbsK");
                        }}
                    >
                        <span className="strike">
                          €7.99
                        </span> €2.99
                      </a>
                  </div>
                  <div className="tokenAmount">25 Tokens</div>
                </div>
                <div className="payment-card middle">
                  <h3>Enhanced Ecstasy</h3>
                  <p>You're an absolute legend, who needs more than the average guy.</p>
                  <div className="payButtonWrapper">
                    <a 
                      className="payButton"
                      onClick={() => {
                        paymentWithStripe("price_1OUnjlEsKxxKHfJy8urThzlC");
                        }}
                    >                      
                    <span className="strike">€19.99</span> €9.99</a>
                    </div>
                  <div className="tokenAmount">100 Tokens</div>
                </div>
                <div className="payment-card">
                  <h3>Limitless Lust</h3>
                  <p>Johnny Sins himself is afraid of you. <br/> We are too. <b>You're a king.</b></p>
                  <div className="payButtonWrapper">
                    <a 
                      className="payButton"
                      onClick={() => {
                        paymentWithStripe("price_1OUnkXEsKxxKHfJysI3RH4pl");
                        }}
                    >      
                      €49.99</a>
                    </div>
                  <div className="tokenAmount">1000 Tokens</div>
    
                </div>
              </div>
              <div className="disclaimer">
                Important: when making the payment, use the same email address as on lolligirls.
              </div>
            </div>
          </div>
        );
      };

      const [isTosModalOpen, setIsTosModalOpen] = useState(false);

      const TosModal = ({ onClose }) => {
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal" onClick={e => e.stopPropagation()}>
              <div className="tosmodalcontent">
              <div className="oneclicksignup">Sign up with one click, get free tokens!</div>
              <div className="accepttos">
                I am 18 years of age or older,<br/>
                and <Link to="/tos" target="_blank" style={{textDecoration:"underline", color:"white", fontWeight:"bold"}}>I accept the terms and conditions.</Link>
              </div>
              <div className="googleLogin" onClick={()=> signInWithGoogle()}>Sign in with Google</div>
            </div>
            </div>
          </div>
        );
      };        
    

    const isImageUrl = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    };

    const isAudioUrl = (url) => {
        return /\.(mp3|wav|ogg)$/.test(url);
    };

    const photos = [
        'anna1.png', 'anna2.png', 'anna3.png', 
        'anna4.png', 'anna5.png', 'anna6.png',
        'anna7.png', 'anna8.png', 'anna9.png', 
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const Modal = ({ onClose }) => {
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modelProfile">
                <img src={modelData?.profile_photo_url} height="100px" width="auto"/>
                <div className="modelName">{modelData?.name}</div>
                <div className="followerCount">{modelData?.followers} followers</div>
                {localStorage.getItem('registeredForNotifications') === 'true' || subscribed ?
                <div className="getNotified-mobile" style={{marginBottom:"50px"}}>Subscribed</div>
                :
                <div className="getNotified-mobile" onClick={() => getNotified()} style={{marginBottom:"50px"}}>Subscribe</div>
                }
            </div>
            <div className="photo-grid">
                {modelData?.gallery_photos?.map((photo, index) => (
                    <img key={index} src={photo} alt={`Photo ${index + 1}`} className="photo-item" onClick={()=>openImageModal(photo)}/>
                ))}
            </div>
            </div>
          </div>
        );
      };

      const [fullScreenUrl, setFullScreenUrl] = useState('');

      const [isImageModalOpen, setIsImageModalOpen] = useState(false);
      const openImageModal = (url) => {
        setFullScreenUrl(url);
        setIsImageModalOpen(true);
      }
      const closeImageModal = () => {
        setFullScreenUrl('');
        setIsImageModalOpen(false);
      }

      const ImageModal = ({ onClose }) => {
          return (
            <div className="backdrop" onClick={onClose}>
              <div className="modal" onClick={e => e.stopPropagation()}>
                <img src={fullScreenUrl} alt="Full Screen" style={{ width: 'auto', maxHeight: '80vh', maxWidth: '90vw', borderRadius:"20px"}} />
            </div>
            </div>
          );
        };

        const [open, setOpen] = React.useState(false);

        const toggleDrawer = (inOpen) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
              return;
            }
        
            setOpen(inOpen);
          };

    return (
        <>
        <div className="chatContainer">  
        <div className="panelContainer">
            <div className="panelContents">
            <div className="brandChat">Lolli<span style={{color:"#F339CA"}}>girls</span></div>

                <div className="panelTitle">
                  
                    <div>Chats</div>
                    {user ?
                    <>
                    {credits > 0 ?
                    <div className="creditAmount" onClick={()=>sweety("Can't buy tokens yet.")}><span>{credits} Tokens</span></div>
                    :
                    <div className="buyCredits" onClick={()=>sweety("Can't buy tokens yet.")}>Buy Tokens</div>
                    }
                    </>
                    : null}
                </div>
                <div className="modelListDiv">
                {models?.map((model, index) => (
                      <div className="menuChatCard" style={{opacity: model?.key == modelData?.key ? 1 : 0.7}} onClick={()=> selectModel(model?.key)}>
                        <img src={model?.profile_photo_url} height="50px" width="auto"/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className="menuChatCardName">{model?.name} {model?.instagram ? <img src="verified.png" width="16pc" title="Model has instagram." /> : null}</div>
                            <div className="menuChatCardDescription">{model?.short_description}</div>
                        </div>
                    </div>
                ))}
                </div>

                <div className="drawerBottomDesktop">
                {user ?
                <div className="drawerBottomDesktopButton" onClick={signOutUser}>
                Log out
                </div>
                :
                <div className="drawerBottomDesktopButton" onClick={() => logmein()}>
                Log in
                </div>
                }
                |
                <div className="drawerBottomDesktopButton" onClick={() => setIsSponsorModalOpen(true)}>
                Sponsors
                </div>
                {user ?
                <>
                |
                <div className="drawerBottomDesktopButton" style={{color:"rgb(255, 0, 247)"}} onClick={() => setIsFeedbackModalOpen(true)}>Give feedback</div>                
                </>
                :
                null
                }
                </div>
            </div>

        </div>
                <div className="chatHeader">
                <img className="hovernoselect" src={modelData?.profile_photo_url} height="50%" width="auto" onClick={()=>setIsModalOpen(true)}/>
                <div className="hovernoselect" onClick={()=>setIsModalOpen(true)}>{modelData?.name}</div>
                <div className="toggleTheme" onClick={toggleDrawer(true)}>
                    <img src="./menuicon.png" height="24px" width="auto" style={{opacity:0.7}} />
                </div>
                {/*<div className="toggleTheme" onClick={toggleTheme}>
                    <img src={theme === 'light' ? 'moon.png' : 'sun.png'} width="24px"/>
                </div>*/}
                <div className="header-right">
                <a className="getNotified" href="/generate">Check landing!</a>
                </div>

            </div>
            <div className="chatApp">
            <div className="chatContent">
            <div className="modelProfile">
                <img onClick={()=>setIsModalOpen(true)} src={modelData?.profile_photo_url} height="100px" width="auto"/>
                <div onClick={()=>setIsModalOpen(true)} className="modelName">{modelData?.name}</div>
                <div onClick={()=>setIsModalOpen(true)} className="followerCount">{modelData?.followers} followers</div>
                {modelData?.instagram ? 
                <a href={modelData?.instagram} target="_blank" className="instagramFollow" style={{marginBottom:"50px"}}><img src="instagram.png" height="20px" /> Instagram</a>
                :
                <a onClick={()=> sweety("Sorry, I'm not on instagram yet.")} target="_blank" className="instagramFollow" style={{marginBottom:"50px"}}><img src="instagram.png" height="20px" /> Instagram</a>
                }
            </div>

            <div 
              style={{
                display: bottomRef.current && (bottomRef.current.getBoundingClientRect().top - 300 <= window.innerHeight) ? 'none' : 'fixed'
              }}
              id={messages?.length}
              className="scrollToBottom" onClick={() => scrollToBottom()}
            >
              <img src="./scrollDown.png" width="20px" /> Go 
            </div>

            {!isNearBottom && (
              <div className="scrollToBottom" onClick={() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                  <img src="./scrollDown.png" width="20px" /> Go down
              </div>
            )}
            {modelData ?
            <>
            {modelData && modelData?.name && messages[modelData?.name]?.length > 0 ? messages[modelData?.name]?.map((msg, index) => (
              <div key={index} className="message" style={{
                  alignSelf: msg.role === "user" ? "flex-end" : "flex-start",
                  background: msg.role === 'system' && isImageUrl(msg.content) ? "none": (msg.role === "user" ? "#2A8BD4" : "#EFEFEF"),
                  color: msg.role === "user" ? "white" : "black"
              }}>
                      {msg.role == 'system' && isImageUrl(msg.content) ? (
                        <div className="imageContainer" >{msg?.tag == "upscaled" || msg?.content == "greeting.png" ? null : <div hidden={modelData?.key == "chat" && msg.content !== "annamariagreeting.png" ? false : true} onClick={()=>improveImage(msg.content)} className="improveimage">Improve photo(2 tokens)</div>} {msg?.tag == "upscaled" ? <div className="upscaledimage" onClick={()=>openImageModal(msg.content)}>Improved photo. <br/>Click to open!</div> : <img onClick={()=>openImageModal(msg.content)} src={msg.content} alt="Chat Image" style={{ width: 'auto', height: '80%', borderRadius:"20px", cursor:"pointer"}} />}</div>
                    ) : msg.role == 'audio' ? (
                        <audio src={msg.content} controls controlsList="nodownload noplaybackrate " />
                    ) : (
                      <p>
                      {msg?.content?.split(/(\*\*[^*]+\*\*)/).map((part, idx) => 
                          part.startsWith("**") && part.endsWith("**") ? 
                              <strong key={idx}>{part.slice(2, -2)}</strong> : 
                              part.replace(/"/g, '') // Replace all double quotes with an empty string
                      )}
                  </p>  // Replace all double quotes with an empty string
                        )}
                </div>
            )) : null}
            </>
            :
            <></>
            }
            {messageLoadingBubbles?.[modelData?.name] ?
            <div className="message" style={{alignSelf:"flex-start", background:"#EFEFEF"}}>
            <div className="loading">
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
          </div>
          </div>
            :
            null}
            <div className="inputArea">
                <input
                    type="text"
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    className="inputField"
                    onKeyPress={handleKeyPress}
                    placeholder="Message..."

                />

                <div onClick={() => sendMessage()}><img className="submitlogo" src="send.png" width="24px" /></div>
            </div>
            <span className="bottomRef" ref={bottomRef}></span>

        </div>
        </div>

        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
        {isImageModalOpen && <ImageModal onClose={() => closeImageModal()} />}
        {isPaymentModalOpen && <PaymentModal onClose={() => setIsPaymentModalOpen(false)} />}
        {isTosModalOpen && <TosModal onClose={() => setIsTosModalOpen(false)} />}
        {isFeedbackModalOpen && <FeedbackModal onClose={() => setIsFeedbackModalOpen(false)} />}
        {isSponsorModalOpen && <SponsorModal onClose={() => setIsSponsorModalOpen(false)} />}
        {solanaModalOpen && <SolanaModal onClose={() => setSolanaModalOpen(false)} />}
        <Drawer open={open} onClose={toggleDrawer(false)} 
        slotProps={{
            content: {
              sx: {
                width:"80vw",
                height:"100vh",
                display:"flex",
                flexDirection:"column",
              },
            },
          }}
          >
            <div className="brandChat">Lolli<span style={{color:"#F339CA"}}>pop</span></div>

            <div style={{padding:"30px", boxSizing:"border-box"}}>
                {credits > 0 ?
                <div className="creditAmount" onClick={()=>sweety("Can't buy tokens yet.")}><span>{credits} Tokens</span></div>
                :
                <div className="buyCredits" onClick={()=>sweety("Can't buy tokens yet.")}>Buy Tokens</div>
                }
            </div>
            {models?.map((model, index) => (
                      <div className="menuChatCard" style={{opacity: model?.key == modelData?.key ? 1 : 0.7}} onClick={()=> selectModel(model?.key)}>
                        <img src={model?.profile_photo_url} height="50px" width="auto"/>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className="menuChatCardName">{model?.name}</div>
                            <div className="menuChatCardDescription">{model?.short_description}</div>
                        </div>
                    </div>
                ))}

            <div className="drawerBottom">
            <a className="getNotified" href="/generate">Generate any girl!</a>

            {user ?<div className="logout" onClick={signOutUser}>
                Log out
            </div>
            :
            <div className="logout" onClick={() => logmein()}>
                Log in
            </div>
            }
            </div>

      </Drawer>

        </div>

        </>
    );
}

export default Chat;
